import React from 'react';

import { API } from '@stoplight/elements';
import '@stoplight/elements/styles.min.css';
import seawayexportYaml from './seawayexport.yaml';


function App() {
  return (
    <div className="App">
      <API
        apiDescriptionUrl={seawayexportYaml}
      />
    </div>
  );
}

export default App;